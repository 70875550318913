import React, { useMemo, useState } from "react";
import { Button, Typography } from "antd";
import QPageLayout from "components/layout/QPageLayout";
import { useTransaction } from "hooks/RT";
import { useTranslation } from "react-i18next";
import { BreadcrumbItemType } from "antd/es/breadcrumb/Breadcrumb";
import { ArrowLeftOutlined } from "@ant-design/icons";
import RTCardsInfo from "components/tenant/recurring_transactions/show/RTInfoCards";
import RelatedDocs from "components/tenant/recurring_transactions/show/RelatedDocs";
import { TableProps } from "antd/lib";

const translationTerms = {
  indexTitle: "recurring_transactions.title",
  title: "recurring_transactions.show.title",
  backBtn: "recurring_transactions.show.back",
  Invoice: "activerecord.commercial_document.invoice",
  Bill: "activerecord.commercial_document.bill",
  SimpleBill: "activerecord.commercial_document.simple_bill",
};

export default function ShowRT() {
  const currentURL = window.location.href;

  const ID = useMemo(() => {
    const match = currentURL.match(/recurring_transactions\/(\d+)/);
    return match ? Number(match[1]) : null;
  }, [currentURL]);

  if (!ID) {
    return null;
  }

  const [RTQuerySearch, setRTQuerySearch] = useState<Record<string, string>>({
    page: "1",
  });

  const { t, i18n } = useTranslation();

  const { data: RTData, isLoading: isRTLInfoLoading } = useTransaction(
    ID,
    new URLSearchParams(RTQuerySearch)
  );

  const RTInfo = RTData?.recurring_transaction;

  const breadCrumbData = useBreadcrumb(
    RTInfo?.[i18n.language === "ar" ? "ar_name" : "en_name"]
  );

  const relatedDocsChangeHandler: TableProps["onChange"] = (
    pagination,
    _,
    __,
    { action }
  ) => {
    if (action === "paginate") {
      setRTQuerySearch((prev) => ({
        ...prev,
        page: pagination.current.toString(),
      }));
    }
  };

  return (
    <QPageLayout
      title={t(translationTerms.title)}
      breadCrumbData={breadCrumbData}
      actionBtns={[
        <Button
          key={"backBTN"}
          type="primary"
          href="/tenant/recurring_transactions"
          icon={<ArrowLeftOutlined />}
        >
          {t(translationTerms.backBtn)}
        </Button>,
      ]}
    >
      <RTCardsInfo RTInfo={RTInfo} isLoading={isRTLInfoLoading} />
      <RelatedDocs
        isLoading={isRTLInfoLoading}
        pageSize={RTData?.pagination.per_page}
        totalDocs={RTData?.pagination.total_entries}
        onChange={relatedDocsChangeHandler}
        documents={RTData?.documents}
        documentType={RTInfo?.document_type}
      />
    </QPageLayout>
  );
}

const useBreadcrumb = (RTName: string | undefined): BreadcrumbItemType[] => {
  const { t } = useTranslation();

  return [
    {
      title: (
        <Typography.Link href="/tenant/recurring_transactions">
          {t(translationTerms.indexTitle)}
        </Typography.Link>
      ),
    },
    {
      title: RTName || "",
    },
  ];
};
