import React, { useState } from "react";
import Link from "antd/es/typography/Link";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import QPageLayout from "components/layout/QPageLayout";
import NoRecurringTransactions from "components/tenant/recurring_transactions/index/NoRecurringTransactions";
import IndexTabs from "components/tenant/recurring_transactions/index/Tabs";
import { useInteractiveFilter } from "hooks/app";
import RecordsTable from "components/tenant/recurring_transactions/index/RecordsTable";
import RecordsFilter from "components/tenant/recurring_transactions/index/RecordsFilter";
import { useRTRecords } from "hooks/RT";

const RecurringTransactions = () => {
  const { t } = useTranslation();

  const pageTitle = t("recurring_transactions.title");

  const [hasFilterUpdated, setHasFilterUpdated] = useState(false);

  const interactiveFilter = useInteractiveFilter(
    {
      setFilterUpdate: setHasFilterUpdated,
      path: "",
    },
    [hasFilterUpdated]
  );

  const { data } = useRTRecords({
    queryKey: ["RTIndex", interactiveFilter.url],
    querySearch: interactiveFilter.url,
    keepPreviousData: true,
  });

  return (
    <QPageLayout
      title={pageTitle}
      breadCrumbData={[
        {
          title: (
            <Link href="#">
              {t("activerecord.attributes.layout.accounting")}
            </Link>
          ),
        },
        { title: pageTitle },
      ]}
      actionBtns={
        <Button type="primary" href="recurring_transactions/new">
          <PlusOutlined /> {t("recurring_transactions.create.button")}
        </Button>
      }
    >
      {data?.records.length === 0 && data?.filters.length === 0 ? (
        <NoRecurringTransactions />
      ) : (
        <>
          <IndexTabs
            form={interactiveFilter.form}
            page={interactiveFilter.page}
            update={interactiveFilter.update}
          />
          <RecordsFilter {...interactiveFilter} />
          <RecordsTable {...interactiveFilter} />
        </>
      )}
    </QPageLayout>
  );
};

export default RecurringTransactions;
