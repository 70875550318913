import React, { useLayoutEffect, useMemo, useRef } from "react";
import {
  Button,
  Flex,
  Modal,
  Space,
  Spin,
  Table,
  TableProps,
  Tag,
  theme,
  Typography,
} from "antd";
import { TagProps } from "antd/lib";
import { useInteractiveFilter, useMediaQuery } from "hooks/app";
import {
  useDeleteRT,
  useRTPermissions,
  useRTRecords,
  useToggleRTStatus,
} from "hooks/RT";
import { useTranslation } from "react-i18next";
import { RTRecord } from "types/RT";
import dayjs from "dayjs";
import { SorterResult } from "antd/es/table/interface";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlayCircleOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import { formatAmountWithCurrency } from "helpers/app";

type Props = Pick<
  ReturnType<typeof useInteractiveFilter>,
  "pageSize" | "page" | "url" | "form" | "sorter"
>;

const translationTerms = {
  columns: {
    reference: "activerecord.external_documents.bill.reference",
    total: "activerecord.attributes.contact_payment.amount",
    status: "activerecord.attributes.invoice.status",
    name: "activerecord.attributes.invoice.name",
    recurringType: "recurring_transactions.index.recurring_type",
    nextDate: "recurring_transactions.index.next_date",
    actions: "recurring_transactions.index.actions",
  },
  statuses: {
    active: "recurring_transactions.index.statuses.active",
    draft: "recurring_transactions.index.statuses.draft",
    inactive: "recurring_transactions.index.statuses.inactive",
    scheduled: "recurring_transactions.index.statuses.scheduled",
    failed: "recurring_transactions.index.statuses.failed",
    completed: "recurring_transactions.index.statuses.completed",
  },
  type: {
    daily: "recurring_transactions.index.daily",
    weekly: "recurring_transactions.index.weekly",
    monthly: "recurring_transactions.index.monthly",
    quarterly: "recurring_transactions.index.quarterly",
    semi_annually: "recurring_transactions.index.semi_annually",
  },
  modal: {
    cancel: "recurring_transactions.index.modal.cancel",
    delete: {
      title: "recurring_transactions.index.modal.delete.title",
      description: "recurring_transactions.index.modal.delete.description",
      okBtn: "recurring_transactions.index.modal.delete.okBtn",
    },
    active: {
      title: "recurring_transactions.index.modal.active.title",
      description: "recurring_transactions.index.modal.active.description",
      okBtn: "recurring_transactions.index.modal.active.okBtn",
    },
    inactive: {
      title: "recurring_transactions.index.modal.inactive.title",
      description: "recurring_transactions.index.modal.inactive.description",
      okBtn: "recurring_transactions.index.modal.inactive.okBtn",
    },
  },
};

// Main Component
const RecordsTable = ({ page, pageSize, url, sorter }: Props) => {
  const { data, isLoading, isFetching, isPreviousData } = useRTRecords({
    queryKey: ["RTIndex", url],
    querySearch: url,
    keepPreviousData: true,
  });

  const columns = useColumns({ sorter });

  const isLargeScreen = useMediaQuery("lg:(size>=992)");

  const tableChangeHandler: TableProps["onChange"] = (
    pagination,
    _,
    sorterObject,
    { action }
  ) => {
    if (action === "paginate") {
      if (page.get() === pagination.current) {
        page.set(1);
      } else if (pagination.current) {
        page.set(pagination.current);
      }

      if (pagination.pageSize) pageSize.set(pagination.pageSize);
    } else if (action === "sort") {
      const { field, order } = sorterObject as SorterResult<any>;

      if (order && field) {
        sorter.set({ field, order });
      } else {
        sorter.reset();
      }
    }
  };

  return (
    <Table
      size={isLargeScreen ? "middle" : "small"}
      onChange={tableChangeHandler}
      loading={isLoading || (isPreviousData && isFetching)}
      rowKey={"id"}
      onRow={(record) => ({
        style:
          record.status === "inactive"
            ? {
                backgroundColor: "#fafafa",
              }
            : undefined,
      })}
      scroll={{
        x: isLargeScreen ? 0 : 1000,
      }}
      pagination={{
        size: isLargeScreen ? "default" : "small",
        current: page.get(),
        total: data?.pagination?.total_entries,
        pageSize: pageSize.get(),
        showSizeChanger: true,
        pageSizeOptions: [10, 15, 25, 50, 75, 100],
        position: ["bottomCenter"],
      }}
      dataSource={data?.records}
      columns={columns}
    />
  );
};

// used in useColumns
const sortByThisColumn = (
  sorter: Props["sorter"],
  filed: keyof RTRecord
): SorterResult<any>["order"] => {
  return sorter.get()?.field === filed
    ? (sorter.get()?.order as SorterResult<any>["order"])
    : undefined;
};

// used in useColumns
const mapColorStatus = (status: RTRecord["status"]): TagProps["color"] => {
  switch (status) {
    case "draft":
      return "orange";

    case "scheduled":
      return "blue";

    case "active":
      return "green";

    case "inactive":
      return "default";

    case "failed":
      return "red";

    case "completed":
      return "cyan";
  }
};

const useColumns = ({
  sorter,
}: {
  sorter: Props["sorter"];
}): TableProps<RTRecord>["columns"] => {
  const { t, i18n } = useTranslation();

  const { isPermissionsLoading } = useRTPermissions();

  const isLargeScreen = useMediaQuery("lg:(size>=992)");

  return [
    {
      dataIndex: "reference",
      title: t(translationTerms.columns.reference),
      sorter: true,
      sortOrder: sortByThisColumn(sorter, "reference"),
      fixed: isLargeScreen ? false : true,
    },
    {
      dataIndex: "documents_total_amount",
      title: t(translationTerms.columns.total),
      sorter: true,
      render(value) {
        return value && formatAmountWithCurrency(value);
      },
      sortOrder: sortByThisColumn(sorter, "documents_total_amount"),
    },
    {
      dataIndex: i18n.language === "ar" ? "ar_name" : "en_name",
      title: t(translationTerms.columns.name),
      sorter: true,
      sortOrder: sortByThisColumn(
        sorter,
        i18n.language === "ar" ? "ar_name" : "en_name"
      ),
    },
    {
      dataIndex: "repeat_term",
      title: t(translationTerms.columns.recurringType),
      sorter: true,
      render(value) {
        return t(
          translationTerms.type[
            value as keyof (typeof translationTerms)["type"]
          ]
        );
      },
      sortOrder: sortByThisColumn(sorter, "repeat_term"),
    },
    {
      dataIndex: "start_date",
      title: t(translationTerms.columns.nextDate),
      sorter: true,
      render(value) {
        return dayjs(value).format("YYYY-MM-DD");
      },
      sortOrder: sortByThisColumn(sorter, "start_date"),
    },
    {
      dataIndex: "status",
      title: t(translationTerms.columns.status),
      sorter: true,
      render(value, record) {
        let color = mapColorStatus(record.status);

        return (
          <Tag color={color}>
            {t(
              translationTerms.statuses[
                value as keyof (typeof translationTerms)["statuses"]
              ]
            )}
          </Tag>
        );
      },
      sortOrder: sortByThisColumn(sorter, "status"),
    },
    {
      title: (
        <Space>
          {t(translationTerms.columns.actions)}
          <Spin spinning={isPermissionsLoading} />
        </Space>
      ),
      width: "20%",
      align: "center",
      render(_, record) {
        return <RowActions record={record} />;
      },
    },
  ];
};

const RowActions = ({ record }: { record: RTRecord }) => {
  const { token } = theme.useToken();

  const { permissions } = useRTPermissions();

  const [modal, contextHolder] = Modal.useModal();

  const confirmModal = useRef<null | ReturnType<(typeof modal)["confirm"]>>(
    null
  );

  const { mutateAsync: updteStatusAsync, isLoading: isUpdatingStatusLoading } =
    useToggleRTStatus();

  const { mutateAsync: deleteRTAsync, isLoading: isDeletingLoading } =
    useDeleteRT();

  const { t } = useTranslation();

  const viewHandler = () => {
    window.location.href = `/tenant/recurring_transactions/${record.id}`;
  };

  const editHandler = () => {
    window.location.href = `/tenant/recurring_transactions/${record.id}/edit/`;
  };

  const deleteHandler = () => {
    confirmModal.current = modal.confirm({
      title: t(translationTerms.modal.delete.title),
      content: (
        <Typography.Paragraph>
          {t(translationTerms.modal.delete.description)}
        </Typography.Paragraph>
      ),
      icon: <DeleteOutlined style={{ color: token.red }} />,
      okText: t(translationTerms.modal.delete.okBtn),
      onOk() {
        return deleteRTAsync(record.id);
      },
    });
  };

  const statusToggleHandler = () => {
    const newStatus = record.status === "inactive" ? "active" : "inactive";

    confirmModal.current = modal.confirm({
      title: t(translationTerms.modal[newStatus].title),
      content: (
        <Typography.Paragraph>
          {t(translationTerms.modal[newStatus].description)}
        </Typography.Paragraph>
      ),
      okText: t(translationTerms.modal[newStatus].okBtn),
      icon:
        newStatus === "active" ? (
          <PlayCircleOutlined style={{ color: token.green }} />
        ) : (
          <PoweroffOutlined style={{ color: token.red }} />
        ),
      onOk() {
        return updteStatusAsync({ id: record.id, status: newStatus });
      },
    });
  };

  useLayoutEffect(() => {
    confirmModal.current?.update({
      okButtonProps: {
        loading: isUpdatingStatusLoading || isDeletingLoading,
      },
      cancelButtonProps: {
        disabled: isUpdatingStatusLoading || isDeletingLoading,
      },
    });
  }, [isUpdatingStatusLoading, isDeletingLoading]);

  const cloneHandler = () => {
    window.location.href = `/tenant/recurring_transactions/${record.id}/edit?clone=true`;
  };

  const { canActivateDeactivate, canDelete, canEdit, canClone } =
    useMemo(() => {
      const canWrite = permissions?.recurring_transactions.write;

      const canActivateDeactivate =
        (record.status === "inactive" ||
          record.status === "active" ||
          record.status === "scheduled") &&
        canWrite;

      const canEdit =
        (record.status === "scheduled" || record.status === "draft") &&
        canWrite;

      const canDelete =
        record.status !== "active" &&
        record.status !== "failed" &&
        permissions?.recurring_transactions.delete;

      const canClone = canWrite;

      return {
        canActivateDeactivate,
        canDelete,
        canEdit,
        canWrite,
        canClone,
      };
    }, [record.status, permissions]);

  return (
    <Flex style={{ marginInline: "auto" }} justify="start" gap={token.marginSM}>
      <Button onClick={viewHandler} icon={<EyeOutlined />} />
      {canEdit && <Button onClick={editHandler} icon={<EditOutlined />} />}
      {canDelete && (
        <Button onClick={deleteHandler} icon={<DeleteOutlined />} />
      )}
      {canActivateDeactivate && (
        <Button
          onClick={statusToggleHandler}
          icon={
            record.status === "inactive" ? (
              <PlayCircleOutlined />
            ) : (
              <PoweroffOutlined />
            )
          }
        />
      )}
      {canClone && <Button onClick={cloneHandler} icon={<CopyOutlined />} />}
      {contextHolder}
    </Flex>
  );
};

export default RecordsTable;
