import { CaretRightOutlined, CloseOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Space, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueries, useQueryClient, UseQueryOptions  } from "react-query";
import { deleteRT, getNextReference, getRTPermissions, getSchedules, getTransaction, getRTRecords, updateStatus, executeSchedule } from "api/RT";
import { CDStatuses, RTDetailsStatuses } from "types/app";
import { DocumentTypes, RecurringTransaction, SelectedDocument,RTIndexResponse, QueryOpts, ScheduleType  } from "types/RT";
import { useStatusMap } from "./status";
import { AxiosError } from "axios";
import { formatAmountWithCurrency } from "helpers/app";

export const useRTPermissions = () => {
  const { data, error, isLoading } = useQuery({
    queryKey: ["RTPermissions"],
    queryFn: getRTPermissions,
  });

  useLayoutEffect(() => {
    if (error) {
      window.location.replace("/tenant/dashboards");
    }
  }, [error]);

  return { permissions: data, isPermissionsLoading: isLoading };
};

export const useSchedules = (
  transactionId: number,
  queryOpts: {
    [ScheduleType.PASSED]: QueryOpts;
    [ScheduleType.UPCOMMING]: QueryOpts;
  }
) => {
  const [passedSchedulesQuery, upcomingSchedulesQuery] = useQueries(
    [ScheduleType.PASSED, ScheduleType.UPCOMMING].map((type) => ({
      queryKey: ["Schedules", type, queryOpts[type]],
      queryFn: () =>
        getSchedules(
          transactionId,
          new URLSearchParams({
            type,
            page: queryOpts[type].page,
            per_page: queryOpts[type].per_page,
          })
        ),
      enabled: queryOpts[type].enabled ?? true,
    }))
  );

  return {
    passedSchedulesQuery,
    upcomingSchedulesQuery,
  };
};

export const useTransaction = (
  id: number | null | undefined,
  querySearch?: URLSearchParams
) => {
  const query = useQuery({
    queryKey: ["transaction", id, querySearch?.toString()],
    queryFn: () => getTransaction(id, querySearch),
    enabled: Boolean(id),
  });

  return query;
}

interface DocumentsColumnsProps {
  handleDeleteDocument: (id: number) => void;
}

export const useDocumentsColumns = ({ handleDeleteDocument }: DocumentsColumnsProps) => {
  const { t } = useTranslation();
  const statusMap = useStatusMap();

  const columns: ColumnsType<SelectedDocument> = [
    {
      title: t("recurring_transactions.table_headers.reference"),
      dataIndex: "reference",
      key: "reference",
    },
    {
      title: t("recurring_transactions.table_headers.amount"),
      dataIndex: "amount",
      render(value) {
        return value && formatAmountWithCurrency(value);
      },
      key: "amount",
    },
    {
      title: t("recurring_transactions.table_headers.date"),
      dataIndex: "date",
      key: "date",
    },
    {
      title: t("recurring_transactions.table_headers.document_type"),
      dataIndex: "type",
      key: "type",
      render: (type: DocumentTypes) => type.slice(0, -1),
    },
    {
      title: t("recurring_transactions.table_headers.status"),
      dataIndex: "status",
      key: "status",
      render: (status: CDStatuses) => {
        const statusData = statusMap[status];
        return (
          statusData && (
            <Typography.Text style={{ color: statusData.color }}>
              {statusData.label}
            </Typography.Text>
          )
        );
      },
    },
    {
      title: t("recurring_transactions.table_headers.actions"),
      dataIndex: "id",
      key: "id",
      render: (id: number, record: SelectedDocument) => {
        let documentType: string = "";
        if (record.type === "SimpleBills") {
          documentType = "simple_bills";
        } else if (record.type === "Bills" || record.type === "Invoices") {
          documentType = record.type.toLowerCase();
        }
        return (
          <Space>
            <Button icon={<EyeOutlined />} href={`/tenant/${documentType}/${id}`} target="_blank" />
            <Button icon={<DeleteOutlined />} onClick={() => handleDeleteDocument(id)} />
          </Space>
        );
      },
    },
  ];
  return columns;
};

interface TransactionsColumnsProps {
  handleStartProcess: (id: number) => void;
  handleSkipProcess: (id: number) => void;
  handleSkipProcessModal: (isOpen: boolean, id?: number) => void;
}

export const useTransactionsColumns = ({
  handleStartProcess,
  handleSkipProcess,
  handleSkipProcessModal,
}: TransactionsColumnsProps) => {
  const { t } = useTranslation();
  const statusMap = useStatusMap();

  const mutation = useMutation({
    mutationFn: async (id: number) => {
      handleSkipProcess(id);
    },
    onSuccess: () => {
      handleSkipProcessModal(false);
    },
    onError: (error) => {
      console.error("Failed to skip process:", error);
    },
  });

  const columns: ColumnsType<RecurringTransaction> = [
    {
      title: t("recurring_transactions.table_headers.reference"),
      dataIndex: "reference",
      key: "reference",
      render: (reference: string, record: RecurringTransaction) => {
        return record.name ? (
          <Tooltip
            placement="right"
            title={t("recurring_transactions.table_headers.reference_hover")}
          >
            <Typography.Link href={`/tenant/recurring_transactions/${record.id}`} target="_blank">
              {reference}
            </Typography.Link>
          </Tooltip>
        ) : (
          reference
        );
      },
    },
    {
      title: t("recurring_transactions.table_headers.name"),
      dataIndex: "name",
      key: "name",
      width: "20%",
    },
    {
      title: t("recurring_transactions.table_headers.created_date"),
      dataIndex: "created_date",
      key: "created_date",
      width: "20%",
    },
    {
      title: t("recurring_transactions.table_headers.date_next_run"),
      dataIndex: "date_next_run",
      key: "date_next_run",
      width: "20%",
    },
    {
      title: t("recurring_transactions.table_headers.status"),
      dataIndex: "status",
      key: "status",
      render: (status: RTDetailsStatuses) => {
        const statusData = statusMap[status];
        return (
          statusData && (
            <Typography.Text style={{ color: statusData.color }}>
              {statusData.label}
            </Typography.Text>
          )
        );
      },
    },
    {
      title: t("recurring_transactions.table_headers.actions"),
      dataIndex: "id",
      key: "id",
      render: (id: number, record: RecurringTransaction) => {
        return (
          record.name && (
            <Space>
              <Button icon={<CaretRightOutlined />} onClick={() => handleStartProcess(id)} />
              <Button icon={<CloseOutlined />} onClick={() => handleSkipProcessModal(true, id)} />
            </Space>
          )
        );
      },
    },
  ];
  return { columns, mutation };
};

export const useNextReference = () => {
  const { data, refetch } = useQuery({
    queryKey: "recurringTransactionNextReference",
    queryFn: () => getNextReference(),
  });

  return { nextReference: data?.next_reference, refetch };
};


export const useRTRecords = ({
  querySearch,
  ...queryOptions
}: {
  querySearch: string;
} & UseQueryOptions<RTIndexResponse, AxiosError>) => {
  const query = useQuery({
    queryFn: ()=>getRTRecords(querySearch),
    ...queryOptions,
  });

  return query;
};


export const useToggleRTStatus = () => {
  const queryClient = useQueryClient();

  const query = useMutation({
    mutationKey: "RTToggleStatus",
    mutationFn: updateStatus,
    async onSuccess() {
      await queryClient.invalidateQueries({
        queryKey: ["RTIndex"],
      });
    },
  });
  return {
    ...query,
    data: query.data?.data,
  };
};

export const useDeleteRT = () => {
  const queryClient = useQueryClient();

  const query = useMutation({
    mutationKey: "RTDelete",
    mutationFn: deleteRT,
    async onSuccess() {
      await queryClient.invalidateQueries({
        queryKey: ["RTIndex"],
      });
    },
  });
  return {
    ...query,
    data: query.data?.data,
  };
};

export const useExecuteSchedule = () => {
  const queryClient = useQueryClient();

  const query = useMutation({
    mutationKey: "RTExecuteSchedule",
    mutationFn({ id, operation }: { id: number; operation: "run" | "skip" }) {
      return executeSchedule(id, operation);
    },
    async onSuccess() {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["Schedules"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["transaction"],
        }),
      ]);
    },
    async onError() {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["Schedules"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["transaction"],
        }),
      ]);
    },
  });
  return query;
};