import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import DocumentsTable from "./DocumentsTable";
import DocumentsFitlers from "./DocumentsFitlers";
import {
  Button,
  GetProp,
  Modal,
  Table,
  Tabs,
  theme,
  Tooltip,
  TableProps,
  Flex,
} from "antd";
import { useTranslation } from "react-i18next";
import { TabsProps } from "antd";
import { RTPermissions, DocumentTypes, SelectedDocument } from "types/RT";
import { useRTPermissions } from "hooks/RT";
import { InvoiceData, SimpleBillData } from "types/app";
import { useInteractiveFilter, useMediaQuery } from "hooks/app";
import { SorterResult } from "antd/es/table/interface";

type Documents = InvoiceData | SimpleBillData;

interface Props {
  isOpen: boolean;
  onSave: (selectedDocuments: SelectedDocument[]) => void;
  onClose: () => void;
  savedSelectedDocuments: SelectedDocument[];
}

const translationTerms = {
  Invoices: {
    tabTitle: "activerecord.external_documents.invoice.title",
  },
  Bill: {
    tabTitle: "activerecord.external_documents.bill.title",
  },
  SimpleBills: {
    tabTitle: "activerecord.external_documents.simple_bill.title",
  },
  modal: {
    title: "recurring_transactions.select_documents.title",
    saveSelections: "recurring_transactions.actions.save_selection",
    cancel: "recurring_transactions.actions.cancel",
  },
  tabs: {
    unauthorizedMessage:
      "recurring_transactions.select_documents.unauthorized_message",
  },
};

const documentsKeys: DocumentTypes[] = ["Invoices", "Bills", "SimpleBills"];

const documentsTokens: {
  [key in DocumentTypes]: {
    translations: string;
    permissionKey: keyof RTPermissions;
  };
} = {
  Invoices: {
    translations: translationTerms["Invoices"].tabTitle,
    permissionKey: "invoices",
  },
  Bills: {
    translations: translationTerms["Bill"].tabTitle,
    permissionKey: "bills",
  },

  SimpleBills: {
    translations: translationTerms["SimpleBills"].tabTitle,
    permissionKey: "simple_bills",
  },
};

const trasnfromSearchParams: (
  searchParams: URLSearchParams
) => URLSearchParams = (s) => {
  if (s.has("inventory_list[]")) {
    const inventories = s.get("inventory_list[]")?.split(",");

    inventories?.forEach((inv, i) => {
      if (i === 0) {
        s.set("inventory_list[]", inv);
      } else {
        s.append("inventory_list[]", inv);
      }
    });
    return s;
  }
  return s;
};

//The Main Component
function Documents({ isOpen, onSave, onClose, savedSelectedDocuments }: Props) {
  const [selectedDocuments, setSelectedDocuments] = useState<
    SelectedDocument[]
  >([]);

  const [hasFilterUpdated, setHasFilterUpdated] = useState(false);

  const selectedKeys = useMemo(
    () => selectedDocuments.map((doc) => doc.id),
    [selectedDocuments]
  );

  const { t } = useTranslation();

  const { token } = theme.useToken();

  const [activeTab, setActiveTab] = useState<DocumentTypes | null>(null);

  const tabItems = useTabItems(activeTab, selectedKeys.length > 0);

  const { isPermissionsLoading, permissions } = useRTPermissions();

  const isLargeScreen = useMediaQuery("lg:(size>=992)");

  const { form, page, pageSize, setFormContainerRef, sorter, update, url } =
    useInteractiveFilter(
      {
        setFilterUpdate: setHasFilterUpdated,
        path: "",
        shouldUpdateURL: false,
        trasnfromSearchParams,
      },
      [hasFilterUpdated]
    );

  const cancelHandler = () => {
    onClose();
  };

  const saveSelectionHandler = () => {
    onSave(selectedDocuments);
    onClose();
  };

  const tabChangeHandler: TabsProps["onChange"] = (activeKey) => {
    setActiveTab(activeKey as DocumentTypes);
    setSelectedDocuments([]);
    page.set(1);
    form.resetFields();
    update();
  };

  const selectRowsHandler: GetProp<
    typeof Table<Documents>,
    "rowSelection"
  >["onChange"] = (_, rows) => {
    setSelectedDocuments(
      rows.map((doc) => {
        return {
          amount:
            activeTab === "SimpleBills"
              ? (doc as SimpleBillData).total_amount
              : (doc as InvoiceData).total,
          date: doc.issue_date,
          id: doc.id,
          reference: doc.reference,
          status: doc.status,
          type: activeTab!,
        };
      })
    );
  };

  const resetSelection = () => {
    setSelectedDocuments([]);
  };

  const tableChangeHandler: TableProps["onChange"] = (
    pagination,
    _,
    sorterObject,
    { action }
  ) => {
    if (action === "paginate") {
      // paginate action triggered in response to changing page size
      if (page.get() === pagination.current) {
        page.set(1);
      } else if (pagination.current) {
        page.set(pagination.current);
      }
      if (pagination.pageSize) pageSize.set(pagination.pageSize);
    } else if (action === "sort") {
      const { field, order } = sorterObject as SorterResult<any>;

      let fieldToSort = field;

      if (field === "total") {
        fieldToSort = "total_amount";
      }

      if (field === "due_amount") {
        fieldToSort = "remaining_amount";
      }

      if (Array.isArray(field) && field.some((f) => f === "contact")) {
        fieldToSort = field.join("_");
      }

      sorter.set({ field: fieldToSort, order });
    }
    setSelectedDocuments([]);
  };

  useEffect(() => {
    if (!isPermissionsLoading && permissions?.invoices.write) {
      setActiveTab("Invoices");
    }
  }, [isPermissionsLoading, permissions]);

  useLayoutEffect(() => {
    if (isOpen) {
      setSelectedDocuments(savedSelectedDocuments);
      if (savedSelectedDocuments.length) {
        setActiveTab(savedSelectedDocuments[0].type);
      }
    }
  }, [isOpen]);

  return (
    <Modal
      title={t(translationTerms.modal.title)}
      onCancel={cancelHandler}
      footer={
        <ModalFooter onCancel={cancelHandler} onSave={saveSelectionHandler} />
      }
      open={isOpen}
      width={"80vw"}
      centered
      styles={{ header: { marginBottom: token.marginLG } }}
    >
      <div style={{ height: isLargeScreen ? "80vh" : "auto" }}>
        <Tabs
          size="large"
          activeKey={activeTab ?? ""}
          tabBarGutter={token.marginXS}
          type="card"
          items={tabItems}
          onChange={tabChangeHandler}
        />
        <Flex
          vertical
          gap={isLargeScreen ? token.marginXL : token.margin}
          style={{ marginTop: token.margin }}
        >
          <DocumentsFitlers
            resetSelection={resetSelection}
            documentType={activeTab}
            form={form}
            setFormContainerRef={setFormContainerRef}
            update={update}
          />
          <DocumentsTable
            currentPage={page.get()}
            pageSize={pageSize.get()}
            selectedKeys={selectedKeys}
            documentType={activeTab}
            onChange={tableChangeHandler}
            searchParams={url}
            onSelect={selectRowsHandler}
          />
        </Flex>
      </div>
    </Modal>
  );
}

const ModalFooter = ({
  onSave,
  onCancel,
}: {
  onSave: () => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const { token } = theme.useToken();

  const isSmallScreen = useMediaQuery("(max-width:991px)");

  return (
    <Flex justify={isSmallScreen ? "center" : "end"} gap={token.margin}>
      <Button key={"saveSelection"} onClick={onSave} type="primary">
        {t(translationTerms.modal.saveSelections)}
      </Button>
      <Button key={"cancel"} onClick={onCancel}>
        {t(translationTerms.modal.cancel)}
      </Button>
    </Flex>
  );
};

const useTabItems = (
  activeTab: DocumentTypes | null,
  hasRowsSelected: boolean
): TabsProps["items"] => {
  const { isPermissionsLoading, permissions } = useRTPermissions();

  const { t } = useTranslation();

  return documentsKeys.map((id) => {
    const hasPermission =
      !isPermissionsLoading &&
      permissions &&
      permissions[documentsTokens[id].permissionKey].write;

    return {
      key: id,
      label: (
        <Tooltip
          title={!hasPermission && t(translationTerms.tabs.unauthorizedMessage)}
        >
          {t(documentsTokens[id].translations)}
        </Tooltip>
      ),
      disabled: !hasPermission || (hasRowsSelected && activeTab !== id),
    };
  });
};

export default Documents;
